







































































































































































































































































































import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import { Vue, Component, Watch } from 'vue-property-decorator'
import PopupBox from '../components/PopupBox/index.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { EnumStatus, EnumActions } from '@/app/infrastructures/misc/Constants/pickup'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import CheckBoxMinusIcon from '@/app/ui/assets/check_box_minus_2.vue'
import IconWarningCircle from '@/app/ui/assets/icon_warning_circle.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalSuccess from '../components/Modals/ModalSuccess.vue'
import ModalConfirmation from '../components/Modals/ModalConfirmation.vue'
import controller from '@/app/ui/controllers/ReschedulePickupController'
import { ReschedulePickup, SubDistrict, Village } from '@/domain/entities/ReschedulePickup'
import Badge from '@/app/ui/components/Badge/index.vue'
import {
  EnumReschedulePickupStatus,
  EnumReschedulePickupType,
  IHeaderCell
} from '@/app/infrastructures/misc/Constants/reschedulePickup'
import { Utils } from '@/app/infrastructures/misc'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import PriorityIcon from '@/app/ui/assets/ics_f_priority-fast.vue'

interface Dropdown {
  value: string | number
  label: string
}

interface IStyle {
  minWidth: string
  maxWidth: string
}

interface DataChecked {
  id: number
  status: string
  value: string
  courier: string
}

interface ICell {
  title: boolean
  customStyle: IStyle
}

interface IParams {
  q: string
  date: Date | null
  village: Dropdown | undefined
  subDistrict: Dropdown | undefined
  schedule: Dropdown
  submission: Dropdown
  page: number
  perPage: number
}

interface IDataTableReschedule {
  title?: boolean
  value?: string | number | IRescheduleStatuses[] | Date
  customStyle?: IStyle
  id?: string | number
  isPriority?: boolean
  pickupBoosterAmount?: number
}

interface IRescheduleStatuses {
  status?: string
}

@Component({
  components: {
    TextInput,
    DateTimePicker,
    PopupBox,
    ExpandIcon,
    DropdownSelect,
    Button,
    DataTableV2,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    CheckBoxMinusIcon,
    IconWarningCircle,
    PaginationNav,
    ModalConfirmation,
    ModalSuccess,
    Badge,
    LoadingOverlay,
    ModalAction,
    PriorityIcon
  },
})
export default class Reschedule extends Vue {
  controller = controller
  EnumReschedulePickupStatus = EnumReschedulePickupStatus
  EnumReschedulePickupType = EnumReschedulePickupType
  popupFilterVisible = false
  appliedFilter = 0
  EnumStatus = EnumStatus
  EnumActions = EnumActions
  mainCheckbox: 'all' | 'partial' | 'none' = 'none'
  ids: Array<number> = []
  dataOnChecked: DataChecked[] = []
  isButtonDisable = true
  requestApprove: string[] = []
  showModal = {
    confirm: false,
    success: false,
    connection: false
  }
  villageOptions: Array<Dropdown> = []
  subDistrictOptions: Array<Dropdown> = []
  dataReschedule: Array<Array<string | number | IDataTableReschedule>> = []

  rescheduleOptions: Array<Dropdown> = [
    { label: 'Semua', value: '' },
    { label: 'Pergantian Waktu', value: EnumReschedulePickupType.CHANGE_TIME },
    { label: 'Pergantian Waktu Dan Kurir', value: EnumReschedulePickupType.CHANGE_TIME_AND_COURIER },
  ]

  submissionOptions: Array<Dropdown> = [
    { label: 'Semua', value: '' },
    { label: 'Menunggu', value: 'Waiting_For_Approval' },
    { label: 'Diterima', value: 'Approve' }
  ]

  perPageOptions: Dropdown[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 }
  ]

  parameters: IParams = {
    q: '',
    date: null,
    village: this.villageOptions[-1],
    subDistrict: this.subDistrictOptions[-1],
    schedule: this.rescheduleOptions[0],
    submission: this.submissionOptions[0],
    page: 0,
    perPage: 10,
  }
  customDateShortcuts = [
    { key: 'chooseDate', label: 'Tentukan Tanggal', value: 0 },
    { key: 'last7Days', label: '7 Hari Terakhir', value: 7 },
    { key: 'last14Days', label: '14 Hari Terakhir', value: 14 },
    { key: 'last30Days', label: '30 Hari Terakhir', value: 30 },
  ]

  headers = [
    this.headerCellMapper('checkbox', '60px'),
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Group ID', '130px'),
    this.headerCellMapper('Order ID', '120px'),
    this.headerCellMapper('Customer', '140px'),
    this.headerCellMapper('No. Handphone', '150px'),
    this.headerCellMapper('Status', '136px'),
    this.headerCellMapper('Tipe Jadwal Ulang', '160px'),
    this.headerCellMapper('Kurir', '220px'),
    this.headerCellMapper('Waktu Jadwal Ulang', '188px'),
    this.headerCellMapper('Alamat', '220px'),
    this.headerCellMapper('Waktu Pengajuan', '180px'),
    this.headerCellMapper('Durasi', '140px'),
    this.headerCellMapper('Pengajuan', '170px'),
    this.headerCellMapper('Aksi', '190px'),
  ]

  created(): void {
    controller.getSubDistrict()
    this.getAllReschedulePickups(this.parameters, true)
  }

  mounted(): void {
    window.addEventListener('offline', this.onHandleOffline)
  }

  private onHandleOffline(): void {
    if(controller.isLoading) {
      controller.setLoading(false)
      this.showModal.confirm = false
      this.showModal.connection = true
    }
  }

  private refetchFilter = Utils.debounce(() => {
    this.getAllReschedulePickups(this.parameters, true)
  }, 500)

  private getSearchDataReschedule(value: string): void {
    if(value.length > 2 || !value) {
      this.refetchFilter()
    }
  }

  convertCurrencyRp(amount: number) {
    return `Rp${Utils.currencyDigit(amount)}`
  }

  private manageDataReschedulePickups(reschedules: ReschedulePickup[]): void {
    this.dataReschedule =  reschedules.map((item, idx) => {
      return [
        this.tableCellMapper(false, undefined, '60px'),
        this.tableCellMapper(undefined, `${idx + 1 + this.parameters.perPage * (this.parameters.page -1)}.`, '60px'),
        this.tableCellMapper(undefined, item?.groupId || '-', '130px', undefined, item.isPriority, item.pickupBoosterAmount || 0),
        this.tableCellMapper(undefined, item?.shipmentId || '-', '120px'),
        this.tableCellMapper(undefined, item?.customerName || '-', '140px'),
        this.tableCellMapper(undefined, item?.customerPhoneNumber || '-', '150px'),
        this.tableCellMapper(undefined, item?.statuses || [], '136px'),
        this.tableCellMapper(undefined, this.formattedRescheduleType(item.rescheduleType), '160px'),
        this.tableCellMapper(
          undefined,
          item.courier?.courierId ?
          `[${item.courier?.courierId}] ${item.courier?.fullname} (${item.courier?.courierType}) ${item.courier?.phoneNumber}`
          : '',
          '220px'
        ),
        this.tableCellMapper(
          undefined,
          item.rescheduledAt ? Utils.formatTimeZone(
            Utils.formatDateWithIDLocale(
              item.rescheduledAt,
              'DD MMM YYYY HH:mm Z'
            )
          ) : '-',
          '188px'
        ),
        this.tableCellMapper(undefined, item?.customerAddress || '-', '220px'),
        this.tableCellMapper(
          undefined,
          item.createdAt ? Utils.formatTimeZone(
            Utils.formatDateWithIDLocale(
              item.createdAt,
              'DD MMM YYYY HH:mm Z'
            )
          ) : '-',
          '180px'
        ),
        this.tableCellMapper(undefined, item?.duration || '-', '140px'),
        this.tableCellMapper(undefined, this.decideStatus(<string>item?.statusApproval), '172px'),
        this.tableCellMapper(undefined, item?.statusApproval || '-', '190px', item?.shipmentId || '-'),
      ]
    })
  }

  private tableCellMapper(
    title?: boolean,
    value?: string | number | string[] | Date,
    colWidth?: string,
    id?: string,
    isPriority?: boolean,
    pickupBoosterAmount?: number
  ): IDataTableReschedule {
    return {
      title,
      value: Array.isArray(value) ? value.map((item) => {
        return { status: item }
      }) : value,
      customStyle: {
        maxWidth: <string>colWidth,
        minWidth: <string>colWidth
      },
      id,
      isPriority,
      pickupBoosterAmount
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private formattedRescheduleType(status?: string): string {
    if(status === EnumReschedulePickupType.CHANGE_TIME) {
      return 'Pergantian Waktu'
    }

    if(status === EnumReschedulePickupType.CHANGE_TIME_AND_COURIER) {
      return 'Pergantian Waktu dan Kurir'
    }

    if(status === EnumReschedulePickupType.MANUAL_ASSIGN) {
      return 'Manual Assign'
    }

    return '-'
  }

  private getAllReschedulePickups(params: IParams, reset?: boolean): void {
    if(reset) this.parameters.page = 1

    const param: Record<string, string | number | Date> = {
      page: params.page,
      per_page: params.perPage,
      sub_district: params.subDistrict?.value || '',
      village: params.village?.value || '',
      reschedule_type: encodeURI(String(params.schedule?.value) || ''),
      keyword: params.q
    }

    if(params.date) {
      param.date = params.date
      param.time_zone = encodeURIComponent(
        Utils.formatDateWithIDLocale(
          new Date().toDateString(),
          'Z'
        )
      )
    }

    controller.getAll(param)
    this.isButtonDisable = true
  }

  private getAllReschedulePickupsPerPage(value: number): void {
    this.parameters.perPage = value
    this.getAllReschedulePickups(this.parameters, true)
  }

  private getVillages():void {
    const param = {
      name: '',
      sub_district: <string>this.parameters.subDistrict?.value
    }
    controller.getVillage(param)
  }

  private onResetFilterBox(): void {
    this.parameters.village = this.villageOptions[-1]
    this.parameters.subDistrict = this.subDistrictOptions[-1]
    this.popupFilterVisible = false
    this.appliedFilter = 0

    this.getAllReschedulePickups(this.parameters, true)
  }

  private onClickMainCheckBox(): void {
    if (this.mainCheckbox === 'none') {
      this.dataReschedule.forEach(item => {
        const isCheckItem = <ICell>item[0]
        const idItem = <number><unknown>item[8]
        isCheckItem.title = true
        item.splice(0, 1, isCheckItem)
        this.ids.push(idItem)
      })
      this.dataOnChecked = this.dataReschedule.map((data, index) => {
        return {
          id: index,
          status: String((<IDataTableReschedule>data[13]).value),
          value: String((<IDataTableReschedule>data[3]).value),
          courier: String((<IDataTableReschedule>data[8]).value),
        }
      })
    } else if (this.mainCheckbox === 'all' || this.mainCheckbox === 'partial') {
      this.dataReschedule.forEach(item => {
        const isCheckItem = <ICell>item[0]
        isCheckItem.title = false
        item.splice(0, 1, isCheckItem)
      })

      this.dataOnChecked = []
      this.ids = []
    }
  }

  private setItemCheckBox(
    index: number,
    indexField: number,
    value: boolean
  ): void {
    const item = this.dataReschedule[index]
    const isCheckItem = <ICell>item[0]
    const idItem = <number><unknown>item[8]
    isCheckItem.title = !value
    item.splice(indexField, 1, isCheckItem)

    if (isCheckItem.title) {
      this.ids.push(idItem)
      this.dataOnChecked.push({
        id: index,
        status: String((<IDataTableReschedule>this.dataReschedule[index][13]).value),
        value: String((<IDataTableReschedule>this.dataReschedule[index][3]).value),
        courier: String((<IDataTableReschedule>this.dataReschedule[index][8]).value),
      })
    } else {
      const idx = this.ids.indexOf(idItem)
      this.ids.splice(idx, 1)
      this.dataOnChecked = this.dataOnChecked.filter(i => i.id !== index)
    }
  }

  private decideClass(status: string):string {
    if(status === 'Menunggu') {
      return 'warning'
    }
    return 'success'
  }

  private decideStatus(status: string): string {
    switch(status) {
      case EnumReschedulePickupStatus.APPROVE:
        return 'Diterima'
      case EnumReschedulePickupStatus.WAITING_FOR_APPROVAL:
        return 'Menunggu'
      case EnumReschedulePickupStatus.REJECT:
        return 'Ditolak'
    }
    return '-'
  }

  private onActionModal(type: string):void {
    this.showModal.confirm = false
    if(type === EnumActions.SUBMIT) {
      controller.requestApprove(this.requestApprove)
    }

    this.requestApprove = []
  }

  @Watch('parameters', { deep: true })
  onFilterParamChange(): void {
    let count = 0

    if (this.parameters.subDistrict) count++
    if (this.parameters.village) count++

    this.popupFilterVisible = false
    this.appliedFilter = count
  }

  @Watch('dataReschedule')
  setMainCheckboxItem(
    data: Array<
      Array<
        string | number | { customStyle: IStyle; title: boolean } | undefined
      >
    >
  ): void {
    const itemState = data.map(item => {
      if (item[0]) {
        const payload = <ICell>item[0]
        return payload.title
      }
    })
    const checkboxSet = new Set(itemState)

    if (checkboxSet.size === 2) {
      this.mainCheckbox = 'partial'
    } else {
      const value = checkboxSet.values().next().value

      if (value === true) {
        this.mainCheckbox = 'all'
      } else if (value === false) {
        this.mainCheckbox = 'none'
      }
    }
  }

  @Watch('dataOnChecked', { deep: true })
  onDataOnChecked(value: DataChecked[]): void {
    const datas = value.map(item => item)
    this.requestApprove = datas.map((item) => item.value)
    if(
      datas.some(i => i.status !== 'Menunggu') ||
      datas.some(i => i.courier === '') ||
      datas.length === 0
    ) {
      this.isButtonDisable = true
    } else {
      this.isButtonDisable = false
    }
  }

  @Watch('controller.dataSubDistricts')
  onWatchSubDistricts(value: SubDistrict[]): void {
    this.subDistrictOptions = value.map((item: SubDistrict) => {
      return {
        label: <string>item?.subDistrict,
        value: <string>item?.subDistrict
      }
    })
  }

  @Watch('controller.dataVillages')
  onWatchDataVillages(value: Village[]): void {
    this.villageOptions = value.map((item: Village) => {
      return {
        label: <string>item?.village,
        value: <string>item?.village
      }
    })
  }

  @Watch('parameters.subDistrict')
  onWatchSubDistrict(value: Dropdown): void {
    if(value) {
      this.getVillages()
    }
  }

  @Watch('controller.dataReschedulePickups')
  onWatchReschedulePickups(value: ReschedulePickup[]): void {
    this.manageDataReschedulePickups(value)
  }

  @Watch('controller.isApproved')
  onWatchErrorRequest(value: boolean): void {
    if(value) {
      this.showModal.success = true
      controller.setIsApproved(false)
    }
  }

  @Watch('parameters.page')
  onWatchParametersPage(): void {
    this.isButtonDisable = true
    this.dataOnChecked = []
  }

  beforeDestroy(): void {
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
